.react-datepicker__input-container {
  display: flex;
}

.react-datepicker__input-container > input {
  border: none;
  color: #000;
  background: transparent;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: 90px !important;
  text-align: center;
}